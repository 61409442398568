<template>
  <div>
    <wrapper-preview-category customClass="section-preview_lk">
      <grid-table
        :data-rows="organizations"
        :headers="tableHeaders.organizationsMassive"
        :headers-for-rows="tableHeaders.organizationsMassive"
        :loading-data="tableData.loading"
        :num-cols="tableHeaders.countCells"
        max-table-height="700px"
      >
        <template #lookedUpAfterMon="{ row }">
          <v-icon
            v-if="row.lookedUpAfterMon"
            color="success" size="24">mdi-check-bold
          </v-icon>
          <v-icon v-else color="error" size="24">mdi-close-thick</v-icon>
        </template>
        <template #lookedUpAfterGov="{ row }">
          <v-icon
            v-if="row.lookedUpAfterGov"
            color="success" size="24">mdi-check-bold
          </v-icon>
          <v-icon v-else color="error" size="24">mdi-close-thick</v-icon>
        </template>
        <template #downloaded="{ row }">
          <v-icon
            v-if="row.workflow === 'DOWNLOADED_FILE'"
            color="success" size="24">mdi-check-bold
          </v-icon>
          <v-icon v-else color="error" size="24">mdi-close-thick</v-icon>
        </template>
      </grid-table>
      <v-dialog
        v-model="loading"
        width="335"
      >
        <v-card>
          <v-card-title>
            Загрузка данных, подождите
          </v-card-title>
        </v-card>
      </v-dialog>
    </wrapper-preview-category>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import request from "@/services/request";
import WrapperPreviewCategory from "@/components/GridTable/dataFolder/WrapperPreviewCategory";
import AdminQuotaTableHeaders from "@/components/GridTable/dataFolder/AdminQuotaTableHeaders";
import GridTable from "@/components/GridTable/GridTable";

export default {
  name: "AdminOrganizations",
  components: {GridTable, WrapperPreviewCategory},
  data: () => ({
    tableHeaders: [],
    val: 0,
    dialog: false,
    loading: false,
    organizations: [],
    getAll: [],
    tableData: {
      loading: false,
      change: {value: false, id: null},
      error: null,
      editRow: null,
      data: [],
    },
  }),
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    }),
  },
  
  created() {
    this.getAllForMon();
    this.tableHeaders = AdminQuotaTableHeaders(this);
    // this.zxcv();
  },
  methods: {
    async getAllForMon() {
      this.loading = true;
      try {
        const res = await request({
          endpoint: `/api/moduledatagather/getAllForMon`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          this.getAll = res.data;
          // console.log(ooo);
          this.loading = false;
          this.organizations = [];
          console.log('getAllForMon успех');
          for (let i = 0; i < res.data.length; i++) {
            if (this.organizations.length === 0) {
              this.organizations.push(res.data[i]);
            } else if (!this.organizations.find(el => el.sprOrgId === res.data[i].sprOrgId)) {
              this.organizations.push(res.data[i]);
            }
          }
          this.organizations = this.organizations.sort((a, b) => a.sprOrgId - b.sprOrgId);
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
  }
}
</script>

<style lang="sass">
.yellow-background
  background-color: #fff3cd
  color: #D4990A

.section-preview__header
  font-size: 24px
  font-weight: bold
  margin: 16px 0
  color: #334D6E

.dialog-btn
  .v-btn__content
    padding: 10px 5px

.v-autocomplete
  padding: 0 24px

.v-text-field
  padding: 0 24px
</style>